import React from "react";
import DataTable from "react-data-table-component";

import * as PropTypes from "prop-types";
import ApiBranch from "../../services/api.branch";
import ApiBarman from "../../services/api.barman";
import CustomizedDialogs from "../modal-template";
import CreateBarman from "./create-barman";
import {withTranslation} from "react-i18next";
import {Button} from "@mui/material";
import notify from "../../utils/Notify";


 class Employees extends React.Component {
    recordsTotal;
    constructor(props) {
        super(props);
        this.state = {
            barmans: [],
            loading: true,
            totalRows: 0,
            perPage: 10,
            branches: [],
        }
    }
    componentWillMount() {
        // ApiService.list_branches().then(res => this.setState({products: res.data}));
        this.get_branches();
        this.get_barmans();

    }

    get_branches = ()=>{
        ApiBranch.get().then(res => {
            this.setState({
                branches: res.data.data,
                loading: false,
            })
        }).catch(err =>{
            console.log(err.response)
        })
    }

    get_barmans = ()=>{
        ApiBarman.get().then(res => {
            this.setState({
                barmans: res.data.data,
                loading: false,
                totalRows: res.data.recordsTotal,
            })
        }).catch(err =>{
            console.log(err.response)
        })

    }
     commonTranslation = (x) => this.props.t(x, {ns: 'common'})

    deleteStaff = (row) =>  async (event) => {

        if(await notify.Confirm({message: 'Test'})        ){
            console.log(row);
            ApiBarman.delete({id: row.id}).then(res => {
                notify.notifyError("Deleted")

            })
        }


            }

    render() {
        const {barmans, loading, totalRows} = this.state;
        const {t} = this.props;
        const columns = [
            {name: t("barmanTable.id"),            selector: "user"},
            {name: t("barmanTable.username"),      selector: "user_details.username"},
            {name: t("barmanTable.Email"),      selector: "user_details.email"},
            {name: t("barmanTable.Branch"),      selector: 'branch_name'},
           // {
           //     name: 'Poster Button',
           //     button: true,
           //     cell: (row) => <CustomizedDialogs title='Edit'><CreateBarman initial_data={row.user_details}/></CustomizedDialogs>,
           // },
            {name: this.commonTranslation('delete'), cell: (row) => <Button onClick={this.deleteStaff(row)} color={"error"}>Delete</Button>}
        ]

        return (
            <div>
                <CustomizedDialogs title={t('createStaff')}><CreateBarman branches={this.state.branches} /></CustomizedDialogs>

                <div className={''}>
                    <DataTable
                        title=""
                        columns={columns}
                        data={barmans}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                    />
                </div>
                <button className={'.editBtn'}>Go Back</button>
            </div>
        );
    }
}


export default (withTranslation(['manager', 'common']) (Employees));
