/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions';
import '../../assets/css/Register.css';
import '../../assets/css/style.css';
import Notify from "../../utils/Notify";
import ApiBarman from "../../services/api.barman";
import UpdateHook from "../update-hook";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {withTranslation} from "react-i18next";

class CreateBarman extends UpdateHook {
    constructor(props) {
        super(props);
        if(!this.state){
            this.state = {
                first_name: '',
                last_name: '',
                username: '',
                email: '',
                password: '',
                branch: '',
                branches: [],
            }

        }
        console.log(this.state);
    }





    handleSubmit = (event) => {
        event.preventDefault();
        const {
            first_name, username, email, password, branch, last_name, id

        } = this.state;
        const userData = {
            first_name,
            last_name,
            username,
            email,
            password,
            branch,
            id
        };
        if(this.props.barman){
            userData['barman'] = true;
        }
        ApiBarman.post(userData).then(res => {
            Notify.notifySuccess(JSON.stringify(res.data));
        })
            .catch(err => {
                Notify.notifyError(JSON.stringify(err.response.data));
            });
    }

    handleChange = (e) => {

        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})

    render() {
        const {
            first_name, branch, username, email, last_name
        } = this.state;
        const {t} = this.props;

        return (
            <div className="">
                    <section id="register-area">
                        <div className="container">
                            <form onSubmit={this.handleSubmit}>
                                <div className="">
                                    <h1>Register</h1>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <TextField
                                            type="text"
                                            placeholder="First Name"
                                            value={first_name}
                                            name="first_name"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <TextField
                                            type="text"
                                            placeholder="Last Name"
                                            value={last_name}
                                            name="last_name"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <TextField id="standard-basic" label="Username" name="username"
                                                   onChange={this.handleChange}
                                                   value={username}
                                                   required
                                                   fullWidth
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <FormControl style={{minWidth: '3em'}} fullWidth>
                                            <InputLabel id="demo-simple-select-label" style={{minHeight: '2em'}}>{t('branch')}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={branch}
                                                onChange={(e) => this.setState({ branch: e.target.value })}
                                                name='branch'

                                            >
                                                {this.props.branches.map(branch=>(
                                                    <MenuItem value={branch.id}>{branch.display_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-envelope" />
                                        <TextField
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                        />
                                    </div>

                                    <input type="submit" value="REGISTER" className="btn" />
                                </div>
                            </form>
                        </div>
                    </section>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { registerUser })(withTranslation(['manager', 'common']) (CreateBarman));
