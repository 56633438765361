/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser } from '../actions/authActions';
import '../assets/css/Register.css';
import '../assets/css/style.css';
import logo from '../assets/images/logo.png';
import Footer from './layouts/Footer';
import {Button, Input} from "@mui/material";
import ApiService from "../services/api.service";
import Notify from "../utils/Notify";
import {site_urls} from "../constants";

class Register extends Component {


  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      password: '',
    }
    this.formData = {

    }
  }
  updateFormData = event => {
    const {name, value} = event.target;
    this.formData[name] = value;
    this.setState(this.formData);
  }
  handleSubmit = (event) => {
        event.preventDefault();
        const {
          first_name, username, email, password, last_name,
        } = this.state;
        const userData = {
          first_name,
          last_name,
          username,
          email,
          password,

        };
        if(this.props.barman){
          userData['barman'] = true;
        }
        Notify.notifyInfo('Please Wait');
        this.formData['company_name'] = this.formData.first_name;
    ApiService.register(this.formData).then(() => {
      Notify.notifySuccess('Registered Successfully', true, site_urls.login.url);
    }).catch(() => {
      Notify.notifyError('Register Error', );
    })
      }

      handleChange = (e) => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
      };

      render() {
        // const {
        //   first_name, username, password, email, last_name
        // } = this.state;
        return (
          <div className="register-body">
            <div className="register-showcase">
              <header>
                <div className="container">
                  <div id="header-title">
                    <Link to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                </div>
              </header>
              <section id="register-area">
                <div className="container">
                  <div>
                    <div className="register-box">
                      <h1>Register</h1>
                      <div className="register-textbox">
                        <Input
                          type={'text'}
                          placeholder={'Company Name'}
                          name={'first_name'}
                          onChange={this.updateFormData}
                          />
                      </div>

                      <div className="register-textbox">
                        <Input
                          type={'text'}
                          placeholder={'Vat Number'}
                          name={'vat_number'}
                          onChange={this.updateFormData}
                          />
                      </div>

                      <div className="register-textbox">
                        <Input
                          type={'text'}
                          placeholder={'SDI Number'}
                          name={'sdi_number'}
                          onChange={this.updateFormData}
                          />
                      </div>

                      <div className="register-textbox">
                        <i className="fas fa-user" />
                        <Input
                          type="text"
                          placeholder="Username"
                          name="username"
                          onChange={this.updateFormData}
                          required
                        />
                      </div>

                      <div className="register-textbox">
                        <i className="fas fa-envelope" />
                        <Input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={this.updateFormData}
                          required
                        />
                      </div>

                      <Button
                          variant={'outlined'}
                          color={'inherit'}
                          type="submit"
                          value="REGISTER"
                          className="btn"
                          onClick={this.handleSubmit}>
                        Register
                      </Button>
                    </div>
                  </div>
                </div>
              </section>
              <Footer />
            </div>
          </div>
        );
      }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { registerUser })(Register);
