import {connect} from "react-redux";
import React from "react";
import ApiService from "../../services/api.service";
import Notify from "../../utils/Notify";
import ApiSubscriptionTemplate from "../../services/api.subscription_template";
import ApiSubscriptionProduct from "../../services/api.subscription_product";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {withTranslation} from "react-i18next";
import {Button} from "@mui/material";


class CreateSubscriptionProduct extends React.Component{
    state = {
        template: '',
        templates: [],
    }
    componentDidMount() {

        ApiSubscriptionTemplate.get().then(res=>{
            this.setState({templates: res.data.data})
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            template,
        } = this.state;

        const userData = {
            template,
            customer: this.props.userId,
        };
        console.log(userData);

        ApiSubscriptionProduct.post(userData).then(res => {
            // Notify.notifySuccess(JSON.stringify(res.data));
            Notify.notifySuccess("Created", true);
        })
            .catch(err => {
                Notify.notifyError(JSON.stringify(err.response.data));
            });
    }

    handleChange = (e) => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})
    render() {
        const {
            template, templates
        } = this.state;

        const {t} = this.props
        return (
            <section >
                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <h1>{t('createSubscriptionTemplate')}</h1>

                            <div className="register-textbox">
                                <FormControl style={{minWidth: '6em'}}>
                                    <InputLabel id="demo-simple-select-label">Subscription Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={template}
                                        onChange={(e) => this.setState({ template: e.target.value })}
                                        name='template'
                                        required
                                    >
                                        {templates.map(template=>(
                                            <MenuItem value={template.id}>{template.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>                                    </div>

                            <Button onClick={this.handleSubmit}>{this.commonTranslation('create')}</Button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }}


const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, )(withTranslation(['barman', 'common'], ) (CreateSubscriptionProduct));
