import React from "react";
import {Header} from "./layouts/Header";
import Footer from "./layouts/Footer";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import { logoutUser } from '../actions/authActions';
import ApiService from "../services/api.service";

class editBranch extends React.Component{
    constructor(props) {
        super(props);

        const {params} = this.props.match;
        const {id} = params;
        this.state = {
            id,
            barman: [],
        }

    }
    componentDidMount() {
        this.get_branch_details();
    }
    get_branch_details = ()=>{
        ApiService.branch_detail(this.state.id).then(res => {
            // console.log(res.data)
            this.setState(res.data);

    });
}
    render() {


        return (
            <div>
            <Header auth={this.props.auth} logoutUser={logoutUser}/>
            <div className={'container'}>
                <br/>
                <h3>Barman's</h3>
                <table>
                    <thead>
                    <th>Id</th>
                    <th>First name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>UserName</th>
                    </thead>
                    <tbody>

                {(this.state.barman).map((key) => (
                    <tr>
                        <td>{key.pk}</td>
                        <td>{key.user_details.first_name}</td>
                        <td>{key.user_details.last_name}</td>
                        <td>{key.user_details.email}</td>
                        <td>{key.user_details.username}</td>
                    </tr>
                ))}
                    </tbody>

                </table>
            </div>
            <Footer/>
            </div>
        );
    }
}



const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error,
});

export default connect(mapStateToProps, )(withRouter(editBranch));
