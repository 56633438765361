import {connect} from "react-redux";
import {registerUser} from "../../actions/authActions";
import React from "react";
import Notify from "../../utils/Notify";
import UpdateHook from "../update-hook"
import ApiCustomer from "../../services/api.customer";
import {TextField} from "@mui/material";
import {Button} from "@mui/material";

class CreateCustomer extends UpdateHook{
    constructor(props) {
        super(props);
        if(!this.state){
            this.state = {
                first_name: '',
                last_name: '',
                username: '',
                email: '',
            }
        }
        this.updateText = 'Register'
        if (props.initial_data)
            this.updateText = 'Update'

    }


    handleSubmit = (event) => {
        event.preventDefault();
        const {
            first_name, username, email, last_name,id
        } = this.state;
        const userData = {
            first_name,
            last_name,
            username,
            email,
            id,
        };

        ApiCustomer.post(userData).then(res => {
            Notify.notifySuccess(JSON.stringify(res.data), true);
        })
            .catch(err => {
                Notify.notifyError(this.commonTranslation("popups.gotError"));
            });
    }
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})


    handleChange = (e) => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };

    render() {
        const {
            first_name, shop, username, password, email, last_name
        } = this.state;
        return (
                    <section >
                        <div className="container">
                            <form onSubmit={this.handleSubmit}>
                                <div className="">
                                    <h1>{this.updateText} Customer</h1>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <TextField
                                            type="text"
                                            placeholder="First Name"
                                            value={first_name}
                                            name="first_name"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <TextField
                                            type="text"
                                            placeholder="Last Name"
                                            value={last_name}
                                            name="last_name"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <TextField
                                            type="text"
                                            placeholder="Username"
                                            name="username"
                                            onChange={this.handleChange}
                                            value={username}
                                            required
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-envelope" />
                                        <TextField
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <Button type="submit" color={'warning'} variant={'contained'}>{this.updateText}</Button>
                                </div>
                            </form>
                        </div>
                    </section>
        );
    }}


const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { registerUser })(CreateCustomer);
