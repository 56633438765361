import React from "react";
import Header from "./layouts/Header";
import {connect} from "react-redux";
import { Button, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ThemeProvider, Typography} from "@mui/material";
import theme from "../theme";
import Notify from '../utils/Notify';
import ApiService from "../services/api.service"


class changePassword extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            userName: { value: '',},
            previousPassword: {
                visibility: false,
                value: '',
            },
            newPassword: {
                visibility: false,
                value: '',
                error: true,
            },
            confirmPassword: {
                visibility: false,
                value: '',
                error: false,
            },
            error: null,
        };
        // this.form_values = {
        //     userName: { value: '',},
        //     previousPassword: passwordProp,
        //     newPassword: passwordProp,
        //     confirmPassword: passwordProp,
        // };
    }

    handleChange = (name) => (e) => {
        let state = this.state;
        // let val = this.state[name];
        state[name].value = e.target.value;

        state.error = null
        if ( this.state.newPassword.value !== this.state.confirmPassword.value)
            state.error = 'Password are not same.';
        console.log(state)
        this.setState(state);

    }

    handleClickShowPassword = (name) => () => {
        let val = this.state[name];
        // console.log(val);
        val['visibility'] = !val['visibility'];
        this.setState({[name]: val});

    }
    validate = () => {

    }
    onButtonClick = () => {

        Notify.NotifyLoading();
        const previous_token = localStorage.authToken;

        ApiService.login({
            username: this.state.userName.value,
            password: this.state.previousPassword.value,
        }).then(res =>{
            const { token } = res.data;
            localStorage.setItem('authToken', token);
            ApiService.change_password({
                new_password1: this.state.newPassword.value,
                new_password2: this.state.confirmPassword.value,
            }).then(res => {
                Notify.notifySuccess(res.data.detail);
            }).catch(err => {
                Notify.notifyError(err.response.data.new_password2[0])
            }).finally(() => {
                localStorage.setItem('authToken', previous_token);
            })
        }).catch(err => {
            Notify.notifyError(err.response.data.non_field_errors[0])
        })
    }
    render() {
        // const values = {
//
        // }

        return (
            <ThemeProvider theme={theme}>
                <Header/>
                <Grid
                    container
                    justifyContent={'center'}
                    direction={'row'}
                    alignItems={'center'}
                >

                <Grid item xs={8} spacing={2} >

                        <Grid container direction={'column'} spacing={2}>

                            <Grid item>
                        <TextField fullWidth id="outlined-basic" label="Username" variant="outlined" value={this.state.userName.value} onChange={this.handleChange("userName")} />
                            </Grid>
                           <Grid item> <FormControl fullWidth sx={{ m: 1, width: '25ch' }} variant="outlined" >
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={this.state.previousPassword.visibility ? 'text' : 'password'}
                                    value={this.state.previousPassword.value}
                                    onChange={this.handleChange('previousPassword')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword('previousPassword')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.previousPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl></Grid>
                           <Grid item> <FormControl fullWidth sx={{ m: 1, width: '25ch' }} variant="outlined" >
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={this.state.newPassword.visibility ? 'text' : 'password'}
                                    value={this.state.newPassword.value}
                                    onChange={this.handleChange('newPassword')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword('newPassword')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.newPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    error={this.state.error}
                                />
                            </FormControl></Grid>
                           <Grid item> <FormControl fullWidth sx={{ m: 1, width: '25ch' }} variant="outlined" >
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={this.state.newPassword.visibility ? 'text' : 'password'}
                                    value={this.state.confirmPassword.value}
                                    onChange={this.handleChange('confirmPassword')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword('newPassword')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.newPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    error={this.state.error}

                                />
                            </FormControl></Grid>
                            <Grid item>
                                {this.state.error ?
                                <Typography color={'red'}> {this.state.error} </Typography>
                                : ''
                                }
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={'contained'}
                                    color={'secondary'}
                                    onClick={this.onButtonClick}
                                >Update</Button>
                            </Grid>
                        </Grid>

                </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, ) (changePassword);
