import React from "react";



export default class UpdateHook extends React.Component {
    constructor(props) {
        super(undefined);
        if(props.initial_data)
        {
            this.state = props.initial_data;
            if(props.id)
                this.state.id = props.id;
        }

        // console.log(props)

    }

}
