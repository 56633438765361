/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/css/Admin.css';
import '../assets/css/style.css';
import Footer from './layouts/Footer';
import Header from './layouts/Header';
import Customers from "./BarmanComponents/customers";
import SubscriptionTemplate from "./BarmanComponents/subscription-template";
import {withTranslation} from "react-i18next";

class BarmanPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: undefined,
        }
    }

    componentWillMount() {
        // ApiService.list_branches().then(res => this.setState({products: res.data}));
    }

    handleDelete = (id) => {
        this.props.deleteProduct(id);
    }

    render() {
        // const products  = this.state.products;
        const {t} = this.props;

        console.log(this.props);
        return (
            <div>

                <Header/>
                <section className='product-area'>
                    <div className='container'>
                        <h1 id='branches-header'>{t('customers')}</h1>
                        <Customers/>
                    </div>
                </section>

                <section className='product-area'>
                    <div className='container'>
                        <h1 id='branches-header'>{t('subscriptionTemplates')}</h1>
                        <SubscriptionTemplate/>
                    </div>
                </section>


                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    product: state.product,
    error: state.error,
    auth: state.auth,
});
export default connect(mapStateToProps, ) ( withTranslation('barman') (BarmanPage));
