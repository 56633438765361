import React from 'react';
import '../../assets/css/style.css';
import Header from './Header';
import Footer from './Footer';
import ImageUploader from "../../services/ImageUploader";
import { Grid, TextField} from "@mui/material";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {site_urls} from "../../constants";
import {connect} from "react-redux";
class HomeBody extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})

    render() {
        const x = false;
        const { auth, t } = this.props;
        const { isAuthenticated, user } = auth;
        return (

    <div className="landing-page">
      <Header />
      <section id="showcase">
        <div className="container">
            <h1>{user.level === 'barman' ? (
                     <Link style={{color: "white"}} to={site_urls.barman.url}>{t('barmanHeader', {ns: 'common'})}</Link>
                )
                : (
                    <Link style={{color: "white"}} to={site_urls.manager.url}>{t('managerHeader ', {ns: 'common'})}</Link>
                )}</h1>
          <p>
              {t('homebody')}
          </p>
        </div>
      </section>{
        x ?
        <section id="test">
            <div >
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                        <img alt={'preview'} src={this.state.image || 'https://backend.coffeecard.coffee/media/shoaib_RgAMRuj.jpeg'} style={{maxHeight: '3em'}}/>
                    </Grid>
                    <Grid item>
                        <TextField id="input-with-icon-grid" label="Image Url" value={this.state.image} onChange={(event) => this.setState({image: event.target.value})} />
                    </Grid>
                    <Grid item>
                        <ImageUploader set_url={(val)=> this.setState({image: val})}/>
                    </Grid>
                </Grid>
            </div>
        </section>
            :""
    }

      <Footer />
    </div>
  );
}
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, ) (withTranslation(['home', 'common', 'header']) (HomeBody));

