import {connect} from "react-redux";
import {registerUser} from "../actions/authActions";
import React from "react";
import ApiService from "../services/api.service";
import Notify from "../utils/Notify";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";


class CreateCustomer extends React.Component{
    state = {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            first_name, username, email, last_name,
        } = this.state;
        const userData = {
            first_name,
            last_name,
            username,
            email,
        };

        ApiService.create_customer(userData).then(res => {
            Notify.notifySuccess(JSON.stringify(res.data));
        })
            .catch(err => {
                Notify.notifyError(JSON.stringify(err.response.data));
            });
    }

    handleChange = (e) => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };

    render() {
        const {
            first_name, shop, username, password, email, last_name
        } = this.state;
        return (
            <div className="register-body">
                <div className="register-showcase">
                    <Header/>
                    <section id="register-area">
                        <div className="container">
                            <form onSubmit={this.handleSubmit}>
                                <div className="register-box">
                                    <h1>Register</h1>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={first_name}
                                            name="first_name"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            value={last_name}
                                            name="last_name"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <input
                                            type="text"
                                            placeholder="Username"
                                            name="username"
                                            onChange={this.handleChange}
                                            value={username}
                                            required
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-envelope" />
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <input type="submit" value="REGISTER" className="btn" />
                                </div>
                            </form>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        );
    }}


const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { registerUser })(CreateCustomer);
