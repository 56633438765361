import React from "react";
import DataTable from "react-data-table-component";
// import {Modal} from "@matrialui/core";
import CustomizedDialogs from "../modal-template";
import ApiCustomer from "../../services/api.customer";
import CreateCustomer from "./create-customer";
import SubscriptionProduct from "./subscription-product";
import {Grid, TextField} from "@mui/material";
import {site_urls} from "../../constants";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import {withTranslation} from "react-i18next";



const ExpandedCustomerComponent = ({data}) => (
    <Grid >
    <SubscriptionProduct user_id={data.id}/>
    </Grid>
)
class Customers extends React.Component {
    recordsTotal;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            totalRows: 0,
            perPage: 10,
            search_field: '',
        }
    }
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})

    componentDidMount() {
        this.get_branches();
    }

    get_branches = (event)=>{
        if(event)
        event.preventDefault();

        this.setState({loading: true,})
        ApiCustomer.get({
            params:
                {search: this.state.search_field}
        }).then(res => {
            // console.log(res.data.data);
            this.setState({
                data: res.data.data,
                loading: false,
                totalRows: res.data.recordsTotal,
            })
        }).catch(err =>{
            console.log(err.response)
        })
    }
    update_search = (event)=> {
        this.setState({search_field: event.currentTarget.value});
    }

    render() {
        const {data, loading, totalRows} = this.state;
        const {t} = this.props;

        const columns = [
            // {name: "id", selector: "id"},
            {name: "First Name",    selector: "user_details.first_name"},
            {name: "Last Name",     selector: 'user_details.last_name'},
            // {name: "Email",         selector: 'user_details.email'},
            // {name: "Branch", selector: "branch"},
            {
                name: 'Subscriptions',
                cell: row => (<Link component={Button} variant={'contained'} target={'_blank'} to={site_urls.customer_subscriptions.url.replace(':id', row.user_details.id)}>Open</Link>)
            },
            // {
            //     name: 'Edit',
            //     button: true,
            //     cell: (row) => <CustomizedDialogs title='Edit' color={'warning'}><CreateCustomer initial_data={row.user_details} /></CustomizedDialogs>
            // },
        ]

        return (
            <div>
                <CustomizedDialogs title={this.commonTranslation('create') + ' ' + t('customers')}><CreateCustomer/></CustomizedDialogs>
                <div>
                    <form onSubmit={this.get_branches}>
                    <TextField style={{marginTop: '1em'}} id="search" type="text" placeholder="Filter By Name" aria-label="Search Input" value={this.state.search_field} onChange={this.update_search} />

                    <Button
                        style={{margin: '1em'}}
                        variant={"contained"}
                        onClick={this.get_branches}
                    >{this.commonTranslation('search')}</Button>
                    </form>
                </div>
                <div className={''}>
                    <DataTable
                        title=""
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        expandableRows
                        expandableRowsComponent={<ExpandedCustomerComponent/>}
                        onRowClicked={(row) => window.open(site_urls.customer_subscriptions.url.replace(':id', row.user_details.id))}
                    />
                </div>
            </div>
        );
    }
}
export default (withTranslation('barman') (Customers))
