import DrfRestBase from './drf_rest_base';
import http from "./http-common";

export default class ApiSubscriptionProduct extends DrfRestBase{
    static endpoint='/shop/api/subscription-product/';

    static decrement({id, number}){
        return http.post('/shop/api/decrement_one/'+id.toString()+'/'+ number.toString() + '/', )
    }
    static get_status({id}){
        return http.get('/shop/api/decrement_status/'+id+'/', )
    }
}
