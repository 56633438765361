import {logo} from "./logo.png";
export const stripe_public_key = 'pk_test_4OLvDAPjSbdT3im0sUrk4Sb900tbD2neLu';
export const base_site = 'https://backend.coffeecard.coffee';

export const site_urls = {
    home:                           {verboseName: 'Home', url: '/',},
    createBranch:                   {verboseName: 'Create Branch', url: '/create-branch'},
    branches:                       {verboseName: 'Branches', url: '/branches'},
    branchEdit:                     {verboseName: 'Edit Branch', url: '/branch/:id/'},
    createCustomer:                 {verboseName: 'Create Customer', url: '/customer/create/'},
    manager:                        {verboseName: 'Manager page', url: '/manager/'},
    barman:                         {verboseName: 'Barman Page', url: '/barman/'},
    customer_subscriptions:         {verboseName: 'Customer Subscriptions', url: '/customer/subscriptions/:id/'},
    manage_shop:                    {verboseName: 'Manage Shop', url: '/shop/'},
    login:                          {verboseName: "login", url:"/login",},
    register:                       {verboseName: "register", url:"/register",},
    createBarman:                   {verboseName: "createBarman", url:"/create-barman",},
    changePassword:                 {verboseName: "Change Password", url:"/change-password"},
};
