import React from "react";
import DataTable from "react-data-table-component";
import CustomizedDialogs from "../modal-template";
import CreateSubscriptionTemplate from "./create-subscription-template";
import ApiSubscriptionTemplate from "../../services/api.subscription_template";
import {withTranslation} from "react-i18next";




class SubscriptionTemplate extends React.Component {
    recordsTotal;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            totalRows: 0,
            perPage: 10,
        }
    }

    componentDidMount() {
        this.get_branches();
    }

    get_branches = ()=>{
        ApiSubscriptionTemplate.get().then(res => {
            // console.log(res.data.data);
            this.setState({
                data: res.data.data,
                loading: false,
                totalRows: res.data.recordsTotal,
            })
        }).catch(err =>{
            console.log(err.response)
        })
    }
    render() {
        const {data, loading, totalRows} = this.state;
        console.log(this.state)
        const {t} = this.props;
        const columns = [
            // {name: "id", selector: "id"},
            {name: "name",    selector: "name"},
            {name: "Description",     selector: 'description'},
            {name: "Total Items", selector:'items_included'},
            {name: "Bonus Items", selector: "bonus_item"},
            {
                name: 'Edit',
                button: true,
                cell: (row) => <CustomizedDialogs title='Edit'  color='warning'  ><CreateSubscriptionTemplate initial_data={row}/></CustomizedDialogs>
            },
        ]

        return (
            <div>
                <CustomizedDialogs title={t('createSubscriptionTemplate')}><CreateSubscriptionTemplate/></CustomizedDialogs>
                <div className={''}>
                    <DataTable
                        title=""
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                    />
                </div>
            </div>
        );
    }
}

export default (withTranslation('barman')) (SubscriptionTemplate)
