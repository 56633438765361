import React from 'react';
import '../assets/css/style.css';
import HomeBody from './layouts/HomeBody';

export default function Home() {
  return (
    <div>
      <HomeBody />
    </div>
  );
}
