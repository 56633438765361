import axios from "axios";
import {base_site} from "../constants";
let http = axios.create({
    // baseURL: "https://backend.coffeecard.coffee",
    baseURL: base_site,
});

http.interceptors.request.use( (config) =>{
    // console.log(config);
    if (localStorage.authToken) {
        config.headers.common['Authorization'] = 'Token ' + localStorage.authToken;
    }
    return config
})
export default http
