import Swal from 'sweetalert2';


class Notify {
    /**
     * @method notifyError
     * @memberof Notify
     * @static
     * @param {string} error
     *
     * @returns {void}
     */
    static notifyError(error) {
        Swal.fire('', error, 'error');
    }

    /**
     * @method notifySuccess
     * @member Notify
     * @static
     * @param {string} message
     *
     * @param {string} endpoint
     * @param reload
     * @param icon
     * @returns {void}
     */
    static async notifySuccess(message,reload=false,  endpoint='', icon='success') {
        await Swal.fire('', message, icon);
        if(reload){

        if(endpoint)
            window.location = endpoint;
        else
        window.location.reload();
        }
    }
    static notifyInfo(message, icon='info') {
        Swal.fire('', message, icon);

    }
    static NotifyLoading(){
        Swal.showLoading();
    }
    static async Confirm({message="hello",confirmButtonText="Yes", denyButtonText= "No" }){
        let confirmed;
        await Swal.fire({
            title: message,
            showDenyButton: true,
            confirmButtonText,
            denyButtonText,

        }).then((result) => confirmed = result.isConfirmed);
        return confirmed;
        //{
        //    if (result.isConfirmed) {
        //
        //    } else if (result.isDenied) {
        //        Swal.fire('Changes are not saved', '', 'info')
        //    }
        // }
    }
}

export default Notify;
