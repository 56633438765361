import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_it from "./translations/it/common.json";
import home_it from "./translations/it/home.json";
import barman_it from "./translations/it/barman.json";
import manager_it from "./translations/it/manager.json";
import common_en from "./translations/en/common.json";
import home_en from "./translations/en/home.json";
import barman_en from "./translations/en/barman.json";
import manager_en from "./translations/en/manager.json";
i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'it',                              // language to use
    resources: {
        en: {
            common: common_en,               // 'common' is our custom namespace
            home: home_en,
            barman: barman_en,
            manager: manager_en,
        },
        it: {
            common: common_it,
            home: home_it,
            barman: barman_it,
            manager: manager_it,
        },
    },


});


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <App/>
            </I18nextProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
