import React from 'react';
import '../../assets/css/style.css';
import {useTranslation} from "react-i18next";

function Footer() {
    const {t} = useTranslation('common')
  return (
    <div style={{marginTop: '5em'}}>
      <footer>
        <div className="container">
          <h3>
              {t('footer')}
            {' '}
            {new Date().getFullYear()}
          </h3>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
