/* eslint-disable import/prefer-default-export */
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_REQUEST = 'SET_USER_REQUEST';
// export const SET_CREATE_BRANCH = 'SET_CREATE_BRANCH';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const GET_ALL_SALES_REQUEST = 'GET_ALL_SALES_REQUEST';
export const GET_ALL_SALES_SUCCESS = 'GET_ALL_SALES_SUCCESS';
export const GET_ALL_SALES_FAILURE = 'GET_ALL_SALES_FAILURE';

export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT';
