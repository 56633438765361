/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/css/Admin.css';
import '../assets/css/style.css';
import Footer from './layouts/Footer';
import Header from './layouts/Header';
import Branches from "./ManagerComponents/branches";
import Employees from "./ManagerComponents/employees";
import ApiBranch from "../services/api.branch";
import {withTranslation} from "react-i18next";

export class ManagerPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        products: undefined,
      }
    }

    componentWillMount() {
      // ApiService.list_branches().then(res => this.setState({products: res.data}));
      // this.get_branches();
    }

    get_branches = ()=>{
      ApiBranch.get().then(res => {
        this.setState({
          branches: res.data.data,
          loading: false,
          totalRows: res.data.recordsTotal,
        })
      }).catch(err =>{
        console.log(err.response)
      })
    }
    handleDelete = (id) => {
    this.props.deleteProduct(id);
    }
  commonTranslation = (x) => this.props.t(x, {ns: 'common'})

  render() {
  // const {products} = this.state;

  const {t} = this.props;

  return (
    <div>

      <Header/>
      <section className='product-area'>
        <div className='container'>
        <h1 id='branches-header'>{t('branches')}</h1>
        <Branches />
        </div>
      </section>

      <section className='product-area'>
        <div className='container'>
        <h1 id='branches-header'>{this.commonTranslation('barmans')}</h1>
        <Employees />
        </div>
      </section>


      <Footer />
    </div>
  );
}
}

const mapStateToProps = state => ({
  product: state.product,
  error: state.error,
  auth: state.auth,
});
export default connect(mapStateToProps,) (withTranslation(['manager', 'common'])  (ManagerPage));
