import React from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import {Button, LinearProgress} from "@mui/material";
import http from "./http-common";
import CustomizedDialogs from "../components/modal-template";
import PropTypes from "prop-types";
class ImageUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            progress: 0,
            uploading: false,
            force_close: false,
        }
    }
    onUploadProgress = (event) => {
        this.setState({
            progress: Math.round((100 * event.loaded) / event.total),
        });
    }
    upload = () => {
        let form = new FormData();

        form.append('file', this.state.files[0],);
        form.append('post_type', 'profile_pic');
        this.setState({uploading: true})
        http.post('upload-img/',form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: this.onUploadProgress,
            }
        ).then((res) => {
            console.log(res);

            console.log(http.defaults.baseURL + res.data.url);
            if(this.props.set_url){
                this.props.set_url(http.defaults.baseURL + res.data.url);
            }
            this.setState({progress: 0, uploading: false, force_close:true});
            this.setState({force_close:false});

        });
    }
    render() {
        return (
            <CustomizedDialogs title={'Upload Image'} force_close={this.state.force_close} force_open={()=> this.setState({force_close: false})}>
                <div>
                    <DropzoneArea
                        acceptedFiles={['image/*']}
                        dropzoneText={"Drag and drop an image here or click"}
                        onChange={(files) => {this.setState({files: files}); console.log('Files:', files)}}

                    />
                    {this.state.uploading ?
                        <LinearProgress variant="determinate" value={this.state.progress} />
                        :
                        <div></div>
                    }
                    <Button variant={'outlined'} onClick={this.upload}> Upload</Button>
                </div>

            </CustomizedDialogs>
        );
    }
}

ImageUploader.propTypes = {
    set_url: PropTypes.element.isRequired,
};

export default ImageUploader;
