/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
import setAuthToken from '../utils/setAuthToken';
import { GET_USER_ERROR, SET_CURRENT_USER, SET_USER_REQUEST } from './types';
import Notify from '../utils/Notify';
import ApiService from "../services/api.service"

const setUserRequest = () => ({
  type: SET_USER_REQUEST,
});

export const setCurrentUser = decoded => ({
  type: SET_CURRENT_USER,
  payload: decoded,
});

const setUserError = error => ({
  type: GET_USER_ERROR,
  payload: error,
});
export const loginUser = userData => (dispatch) => {
  dispatch(setUserRequest());
  console.log(ApiService)
  return ApiService.login(userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem('authToken', token);

      // setAuthToken(token);
      let detail = ApiService.get_user_detail();
      console.log(detail);
      detail.then((res) => {

          dispatch(setCurrentUser(res.data));

      })

      // decoded.userId === 'admin' ? window.location.replace('/admin') : window.location.replace('/attendant');
    })
    .catch((err) => {
        console.log(err);

      dispatch(setUserError(err.response.data));
      Notify.notifyError('ERROR', err.response.data);
    });
};

export const registerUser = userData => (dispatch) => {
  dispatch(setUserRequest());
  return ApiService.register(userData)
    .then((res) => {
      if (res.data.role === 'user') {
        window.location.replace('/login');
        localStorage.clear();
      }
      Notify.notifySuccess(JSON.stringify(res.data))
    })
    .catch((err) => {
      dispatch(setUserError(err.response.data.message));
      Notify.notifyError(JSON.stringify(err.response.data.message));
    });
};
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('authToken');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};
