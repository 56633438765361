import React from "react";
import DataTable from "react-data-table-component";
import CustomizedDialogs from "../modal-template";
import CreateSubscriptionProduct from "./create-subscription-product";
import ApiSubscriptionProduct from "../../services/api.subscription_product";
import {Button, Grid} from "@mui/material";
import Swal from "sweetalert2";
import {FormControl, IconButton, Input, Slider} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import {withTranslation} from "react-i18next";



class SubscriptionProduct extends React.Component {
    recordsTotal;
    constructor(props) {
        super();
        this.state = {
            data: [],
            loading: true,
            totalRows: 0,
            perPage: 10,
            counts: 1,
        }
    }

    componentDidMount() {
        this.get_branches();
        window.Swal = Swal;
    }
    check_status = ()=> {

        ApiSubscriptionProduct.get_status(this.data.results[0]).then(res =>{
            if(res.data.result!=null){
                this.data = res.data;
                return this.do_after_status();
            }
            setTimeout( () =>  {
                this.check_status();
            }, 2000 )

        });


    }
    do_after_status = ()=> {
        window.data = this.data;
        if (this.data.result)
        Swal.fire({
            title: "Response",
            text: 'Request Accepted',
            icon: "success",

        });
        else
        Swal.fire({
                title: "Response",
                text: 'Request denied',
                icon: "error",
        });


        let loading = false;

        this.setState({loading})
        this.get_branches();

    }
    get_branches = ()=>{
        ApiSubscriptionProduct.get({
        params:
            {customer: this.props.match.params.id, test: 'hello'}

    }).then(res => {
            // console.log(res.data.data);
            this.setState({
                data: res.data.data,
                loading: false,
                totalRows: res.data.recordsTotal,
            })
        }).catch(err =>{
            console.log(err.response)
        })
    }
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})
    decrement_item = async (row) => {
        const sweetAlertResult = await Swal.fire({
            title: this.commonTranslation("popups.areYouSure"),
            text: this.commonTranslation("popups.onceDecrementedYouWillNotBeAbleToUndo"),
            icon: "warning",
            buttons: true,
            dangerMode: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',

            confirmButtonText: this.commonTranslation('popups.confirm'),
            cancelButtonText: this.commonTranslation('popups.cancel'),
            input: 'range',

            inputLabel: 'Templates',
            inputAttributes: {
                min: 1,
                max: row.items_remaining,
                step: 1,
            },
            inputValue: 1
        });
        console.log(sweetAlertResult);
        /*
                    .then((result) =>

         */
        {
            if (sweetAlertResult.isConfirmed) {
                // console.log("Sending request");
                const payload = {number: sweetAlertResult.value.toString(), ...row};
                 ApiSubscriptionProduct.decrement(payload).then(async res=>
                {
                // console.log(res);
                Swal.showLoading()
                this.data = res.data;
                 this.check_status();

                })

            } else {
                // Swal.fire("Cancelled");
            }
        }
    }
    render() {
        const {id} = this.props.match.params;
        const {t} = this.props;
        const {data, loading, totalRows, counts} = this.state;
        const columns = [
            {name: t("subscriptionTable.subscription_name"), selector: "template_detail.name"},
            {name: t("subscriptionTable.created_at"),        selector: 'created_at'},
            {name: t("subscriptionTable.ItemsIncluded"),      selector: 'template_detail.items_included'},
            {name: t("subscriptionTable.ItemsRemaining"),        selector: 'items_remaining'},

            {
                name: t('subscriptionTable.Decrement'),
                button: true,
                cell: (row) => row.items_remaining ?
                    <React.Fragment>


                        <Button variant="outlined" color="primary" onClick={()=>this.decrement_item(row)}>{t('subscriptionTable.Decrement')}</Button>
                    </React.Fragment>
                    : '',

            },

            // {name: "barman_name",       selector: "barman_name"},
            // {name: "template",          selector: "template"},
            // {name: "customer",          selector: "customer"},
            // {name: "barman",            selector: 'barman'},

        ]


        return (

            <Grid item style={{
                marginLeft: '5em',
                marginTop: '2em',
                borderColor: 'black',
            }}>
                <CustomizedDialogs title={t('createSubscriptionTemplate')}><CreateSubscriptionProduct userId={id}/></CustomizedDialogs>
                <div className={''}>
                    <DataTable
                        title={t("subscriptionTemplates")}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                    />
                </div>
            </Grid>
        );
    }
}
// SubscriptionProduct.prototype = {
//     user_id:
// }


export default (withTranslation(['barman', 'common']) (SubscriptionProduct))
