/*
 * @abstract
 */

import http from "./http-common";

export default class DrfRestBase{
    static endpoint;
    static get(config){
        return http.get(this.endpoint, config);
    }
    static post(data){
        console.log(data);
        if(data.id){
            return this.update(data)
        }

        return http.post(this.endpoint, data);
    }
    static update(data){
        return http.patch(this.endpoint+data.id+'/' , data);
    }
    static delete(data){
        return http.delete(this.endpoint+data.id+ '/');
    }
}
