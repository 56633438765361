import React from 'react';
import { withStyles } from '@mui/styles';
import {Button} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import logo from "../logo.png";
import {makeStyles} from "@mui/styles";
import theme from "../theme";


const styles = function () {
    return ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
};

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(() => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);
    const [force, setforced] = React.useState(false);

    const handleClickOpen = () => {

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        // if(props.force_open){
        //     props.force_open();
        // }

    };
    let opened = open;
    if(props.force_close && !force){
        setOpen(false);
        setforced(true);
    }
    if(!props.force_close && force){
        setforced(false);
    }
    let modal_title = <img style={{maxHeight: "1.5em"}} src={logo}/>;
    if (props.modal_title)
        modal_title = props.modal_title;
    return (<div>
                <Button color={props.color} variant={props.variant} onClick={handleClickOpen}>
                    {props.title}
                </Button>
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={opened} maxWidth={"md"} fullWidth style={{zIndex: 1}} >
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            {modal_title}
                        </DialogTitle>
                        <DialogContent >
                            {props.children}
                        </DialogContent>
                    </Dialog>
                </div>);

}

CustomizedDialogs.defaultProps = {
        color: 'success',
        variant: 'outlined'

}

export default CustomizedDialogs
