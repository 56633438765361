/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
// import '../../assets/css/Register.css';
// import '../../assets/css/style.css';
import Notify from "../../utils/Notify";
import UpdateHook from "../update-hook";
import ApiBranch from "../../services/api.branch";
import {Button} from "@mui/material";
import {withTranslation} from "react-i18next";
class CreateBranch extends UpdateHook {
    constructor(props) {
        super(props);
        if(!this.state){
            this.state = {
                location: '',
                display_name: '',
            }
        }
        console.log(this.state);
    }



    handleSubmit = (event) => {
        event.preventDefault();
        const {
            location, display_name,id
        } = this.state;
        const userData = {
            location,
            display_name,
            id
        };
        if( this.props.edit){
            ApiBranch.update(userData).then(res => {
                Notify.notifySuccess("updated Successfully", true)
            }).catch(err =>{
                Notify.notifyError(JSON.stringify(err.response.data))
            });
        }
        else{

        ApiBranch.post(userData).then(res => {
            Notify.notifySuccess("Created", true)
        }).catch(err =>{
            Notify.notifyError(JSON.stringify(err.response.data))
        });
        }

    }

    handleChange = (e) => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})

    render() {
        const {
            location, display_name
        } = this.state;
        const {t} = this.props;
        return (
                    <section>
                        <div className="container">
                            <form onSubmit={this.handleSubmit}>
                                <div className="">
                                    <h1>{t('createBranch')}</h1>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <input
                                            type="text"
                                            placeholder={t('branchForm.location')}
                                            value={location}
                                            name="location"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="register-textbox">
                                        <i className="fas fa-user" />
                                        <input
                                            type="text"
                                            placeholder={t('branchForm.diplayName')}
                                            value={display_name}
                                            name="display_name"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>



                                    <Button onClick={this.handleSubmit}>
                                        {this.props.edit ? this.commonTranslation('update'): this.commonTranslation('create')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </section>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
CreateBranch.PropTypes = {
    create: true
}
export default connect(mapStateToProps, )(withTranslation(['manager', 'common']) (CreateBranch));
