/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../assets/css/style.css';
import { logoutUser } from '../../actions/authActions';
import {site_urls} from "../../constants";
import {withTranslation} from "react-i18next";

export class Header extends Component {
  handleLogout = () => {
    this.props.logoutUser();
    window.location.replace('/login');
  }

  render() {
    const { auth, t } = this.props;
    const { isAuthenticated, user } = auth;
    console.log(this.props);
    // const { }
    const guestLink = (
        <li>
          <Link to="/login">Login</Link>
        </li>
    );
    return (
      <div>
        <header>
          <div className="container">
            <div id="header-title">
                {user.name}
            </div>
            <nav>
                <ul>


              {isAuthenticated !== false ? (
                  <div>
                  <li>
                      <a href="#" onClick={this.handleLogout}>
                          Logout
                          {' '}
                      </a>
                  </li>
                      {user.level === 'barman' ? (
                          <li>
                              <Link to={site_urls.barman.url}>{t('barmanHeader')}</Link>
                          </li>
                          )
                          : (
                              <Link to={site_urls.manager.url}>{t('managerHeader')}</Link>
                          )}

                  </div>

              ) :(
                  <div>
                  <li>
                      {guestLink}
                  </li>
                  <li>
                  <Link to='/register'>Register</Link>
                  </li>
                  <li>
                  <Link to={site_urls.changePassword.url}>Change Password</Link>
                  </li>
                  </div>
              )}

                </ul>
            </nav>
          </div>
        </header>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, { logoutUser }) (withTranslation('common') (Header) );
