import http from "./http-common";
// import cookie from 'react-cookie';

export default class ApiService {
    getAll() {
        return http.get("/tutorials");
    }

    get(id) {
        return http.get(`/tutorials/${id}`);
    }

    create(data) {
        return http.post("/tutorials", data);
    }

    update(id, data) {
        return http.put(`/tutorials/${id}`, data);
    }

    delete(id) {
        return http.delete(`/tutorials/${id}`);
    }

    deleteAll() {
        return http.delete(`/tutorials`);
    }

    findByTitle(title) {
        return http.get(`/tutorials?title=${title}`);
    }

    static login(data){
        /* login
         * {
         * username,
         * password
         * }
         */
        return http.post('get-token/', data);
    }

    static change_password(data){
        /* change_password
         * {
         * new_password1
         * new_password2
         * }
         */
        return http.post('rest-auth/password/change/', data);
    }
    static get_user_detail(){
        return http.get('check-user/',);
    }
    static register(data){
        return http.post('/shop/api/register/', data);
    }
    static create_branch(data){
        return http.post('/shop/api/branch/create/', data);
    }

    static branch_detail(pk){
        return http.get(`/shop/api/branches/${pk}/get_detail/`);
    }
    static create_barman(data){
        return http.post('/shop/api/barman/', data);
    }

    static create_customer(data) {
        return http.post('/shop/api/customer/', data);
    }
}

// export default  ApiService;
