import React from "react";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import ApiBranch from "../services/api.branch";

export default class Branch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            branches: []
        }
    }
    componentDidMount() {
        this.get_branches();
    }
    get_branches = ()=>{
        ApiBranch.get().then(res => {
            this.setState({'branches': res.data});console.log(res.data)
        }).catch(err =>{
            console.log(err.response)
        })
    }
    render() {
        return (
            <div>
                <Header/>
                <div className={'container'}>
                    <table>
                        <thead><tr>
                            <th>Id</th>
                            <th>Location</th>
                            <th>Display Name</th>
                        </tr></thead>
                        <tbody>
                        {this.state.branches.map(branch => (<tr>
                            <td>{branch.id}</td>
                            <td>{branch.location}</td>
                            <td>{branch.display_name}</td>
                        </tr>))}
                        </tbody>
                    </table>
                </div>
                <Footer/>
            </div>
        );
    }
}
