import DrfRestBase from './drf_rest_base';
import http from "./http-common";

export default class ApiCustomer extends DrfRestBase{
    static endpoint='/shop/api/customer/';
    static update(data){
        return http.put('/shop/api/user/'+data.id+'/' , data);
    }

}
