import React from "react";
import './App.css';
import store from "./store";
import {setCurrentUser} from './actions/authActions';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Private from "./common/Private";
import Login from "./components/Login";
import Admin from "./components/manager-page";
import Register from "./components/Register";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import ApiService from './services/api.service';
import {SET_CURRENT_USER} from "./actions/types";
import CreateBarman from "./components/ManagerComponents/create-barman";
import CreateBranch from "./components/ManagerComponents/create-branch";
import CreateCustomer from "./components/create-customer";
import Branch from "./components/Branch";
import Manager from "./common/Manager";
import {site_urls} from "./constants";
import editBranch from "./components/edit-branch";
import ManagerPage from './components/manager-page';
import Barman from "./common/Barman";
import BarmanPage from "./components/barmanpage";
import SubscriptionProduct from "./components/BarmanComponents/subscription-product";
import changePassword from "./components/change-password";
if (localStorage.authToken) {
  store.dispatch(setCurrentUser(1));

  ApiService.get_user_detail().then(res =>{
    store.dispatch(setCurrentUser(res.data));
    console.log(store.getState(SET_CURRENT_USER))

  })
}

function App() {

  return (
      <React.Suspense fallback="loading">
    <div className="App">

      <Provider store={store}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/create-barman" render={(props) => <CreateBarman barman={true}  {...props} />} />
          <Manager exact path={site_urls.manage_shop.url} />
          <Manager exact path={site_urls.createBranch.url} component={CreateBranch} />
          <Manager exact path={site_urls.branches.url} component={Branch} />
          <Manager exact path={site_urls.manager.url} component={ManagerPage} />
          <Barman exact path={site_urls.barman.url}   component={BarmanPage} />
          <Barman exact path={site_urls.customer_subscriptions.url}   component={SubscriptionProduct} />
          <Manager  path={site_urls.branchEdit.url} component={editBranch} />
          <Private  path={site_urls.createCustomer.url} component={CreateCustomer} />
          <Private exact path="/admin" component={Admin} />
            <Route exact path={site_urls.changePassword.url} component={changePassword} />
          <Route component={NotFound} />
        </Switch>
      </Provider>

    </div>
      </React.Suspense>
  );
}

export default App;
