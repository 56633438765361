import { red, yellow } from '@mui/material/colors';
import {makeStyles, createTheme} from '@mui/material/styles';
import poppins from './assets/fonts/Poppins/Poppins-Black.ttf';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#fff7dc',

        },
        secondary: {
            main: yellow.A400,
            light: yellow.A400,
            dark: yellow.A400,
            contrastText: red,
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#e4f9f8',
        },
        type: 'light',

    },
    typography: {
        fontFamily: poppins,
        subtitle1: {
            fontFamily: poppins,
            color: '#55c0df',
        },
        button: {
            color: '#55c0df',
            backgroundColor: '#fff7dc',
            fontStyle: "italic"
        },

    },
    headlines: {
        headline: {
            fontFamily: poppins,
            color: '#55c0df'
        },
        subHeadline: {
            fontFamily: poppins,
            color: '#fac5a8'
        }
    },
    overrides: {
        MuiTableRow: {
            root: {'&:hover': {backgroundColor: 'red'}}
        },
    },

});

export default theme;
