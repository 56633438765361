import {connect} from "react-redux";
import React from "react";
import ApiService from "../../services/api.service";
import Notify from "../../utils/Notify";
import ApiSubscriptionTemplate from "../../services/api.subscription_template";
import {TextField} from "@mui/material";
import {Button, Grid} from "@mui/material";

class CreateSubscriptionTemplate extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            items_included: '',
            bonus_item: '',
        }

        this.CreateButton = 'Create'
        if(props.initial_data){
            this.state = props.initial_data;
            this.state.items_included = this.state.bonus_item;
            this.CreateButton = 'Update'
        }

    }

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            name,
            description,
            items_included,
            bonus_item,
        } = this.state;
        let postData = {
            name,
            description,
            items_included,
            bonus_item,
        };
        postData[items_included] = postData.items_included + postData.bonus_item;
        if(this.props.initial_data){
            ApiSubscriptionTemplate.update(postData).then(async res => {
                await Notify.notifySuccess("Updated Successfully");
                // await Notify.notifySuccess(JSON.stringify(res.data));
                window.location.reload();

            })
                .catch(err => {
                    Notify.notifyError(JSON.stringify(err.response.data));
                });
        }else{

        ApiSubscriptionTemplate.post(postData).then(async res => {
            await Notify.notifySuccess("Created");
            // await Notify.notifySuccess(JSON.stringify(res.data));
            window.location.reload();

        })
            .catch(err => {
                Notify.notifyError(JSON.stringify(err.response.data));
            });
        }

    }

    handleChange = (e) => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };

    render() {
        const {
            name,
            description,
            items_included,
            bonus_item,
        } = this.state;
        return (
            <section >
                <Grid items >
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="register-textbox">
                                <i className="fas fa-user" />
                                <TextField
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    name="name"
                                    onChange={this.handleChange}
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="register-textbox">
                                <i className="fas fa-user" />
                                <TextField
                                    placeholder="Description"
                                    value={description}
                                    name="description"
                                    onChange={this.handleChange}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                />
                            </div>
                            <div className="register-textbox">
                                <i className="fas fa-envelope" />
                                <TextField
                                    type={"number"}
                                    placeholder="Total Items"
                                    name="items_included"
                                    value={items_included}
                                    onChange={this.handleChange}
                                    fullWidth

                                    required
                                />
                            </div>
                            <div className="register-textbox">
                                <i className="fas fa-envelope" />
                                <TextField
                                    type={"number"}
                                    placeholder="Bonus Items"
                                    name="bonus_item"
                                    value={bonus_item}
                                    onChange={this.handleChange}
                                    fullWidth

                                    required
                                />
                            </div>

                            <Button color={'success'} type={"submit"} variant={'contained'} >{this.CreateButton}</Button>
                        </div>
                    </form>
                </Grid>
            </section>
        );
    }}


const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, )(CreateSubscriptionTemplate);
