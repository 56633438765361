import React from "react";
import DataTable from "react-data-table-component";
// import {Modal} from "@matrialui/core";
import * as PropTypes from "prop-types";
import ApiBranch from "../../services/api.branch";
import CustomizedDialogs from "../modal-template";
import CreateBranch from "./create-branch";
import {withTranslation} from "react-i18next";
import logo from "../../logo.png";
import {Image} from "@mui/icons-material";
function Button(props) {
    return (<button>{props.children}</button>);
}

Button.propTypes = {children: PropTypes.node};

class Branches extends React.Component {
    recordsTotal;
    constructor(props) {
        super(props);
        this.state = {
            branches: [],
            loading: true,
            totalRows: 0,
            perPage: 10,
        }
    }
    componentDidMount() {
        this.get_branches();
    }
    get_branches = ()=>{
        ApiBranch.get().then(res => {
            this.setState({
                branches: res.data.data,
                loading: false,
                totalRows: res.data.recordsTotal,
            })
        }).catch(err =>{
            console.log(err.response)
        })
    }
    commonTranslation = (x) => this.props.t(x, {ns: 'common'})

    render() {
        const {branches, loading, totalRows} = this.state;
        const {t} = this.props
        const columns = [
            {name: t("branchTable.id"),              selector: "id"},
            {name: t("branchTable.Location"),        selector: "location"},
            {name: t("branchTable.IdentityName"),   selector: 'display_name'},
            {
                name: 'Poster Button',
                button: true,
                cell: (row) => <CustomizedDialogs title='Edit'><CreateBranch edit={true} initial_data={row}/></CustomizedDialogs>,
            },
        ]
        return (
            <div>

                <CustomizedDialogs title={t('createBranch')} modal_title={<img style={{maxHeight: "1.5em"}} src={logo}/>}><CreateBranch/></CustomizedDialogs>
                <div className={''}>
                    <DataTable
                        title=""
                        columns={columns}
                        data={branches}

                        progressPending={loading}
                        paginationTotalRows={totalRows}
                    />
                </div>
            </div>
        );
    }
}
export default (withTranslation('manager') (Branches));
